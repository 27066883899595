import { fetchFromGraphInternalDevOnly } from '@utils/fetchHttp';
import { jsonStringify } from '@utils/json';
import {
  FC,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { BasisTheoryTokenType } from '..';

export interface BasisTheoryResponseObject {
  iFrameApiKey: string;
  tokenRequestFields: {
    type: string;
    id: string;
    mask: string;
    searchIndexes?: string[];
    fingerprintExpression: string;
    containers: string[];
  };
}

type BasisTheoryDataPayload = anyOk;
interface TokenRequestFields {
  type: string;
  id: string;
  mask: string;
  searchIndexes?: string[];
  fingerprintExpression: string;
  containers: string[];
}

interface BasisTheoryApiState {
  ['DRIVERS_LICENSE']: BasisTheoryResponseObject;
  ['CREDIT_CARD']?: BasisTheoryResponseObject;
  ['CARD_COMDATA_PROPRIETARY']?: BasisTheoryResponseObject;
  ['CARD_COMDATA_MASTERCARD']?: BasisTheoryResponseObject;
}

const defaultResponseObject: BasisTheoryResponseObject = {
  iFrameApiKey: '',
  tokenRequestFields: {
    type: '',
    id: '',
    mask: '',
    fingerprintExpression: '',
    containers: [],
  },
};

const tokenTypePayloadKeyMap = {
  DRIVERS_LICENSE: 'drivers_license_id',
  CARD_EFS: 'number',
  CARD_COMDATA_PROPRIETARY: 'number',
  CARD_COMDATA_MASTERCARD: 'number',
};

interface MetadataSecureInput {
  driverCode?: string;
}

interface HandleTokenizeKwargs {
  tokenType: BasisTheoryTokenType;
  tokenRequestFields: TokenRequestFields;
  data: fixMe;
  metadata: MetadataSecureInput;
  bt: fixMe;
  apiKey: string;
}

interface BasisTheoryContextManager {
  handleTokenize: ({
    tokenType,
    tokenRequestFields,
    data,
    metadata,
  }: HandleTokenizeKwargs) => Promise<anyOk>;
  instantiateBasisTheory: (tokenType: BasisTheoryTokenType) => Promise<void>;
  bt?: anyOk;
  error?: anyOk;
  apiKeys: Partial<BasisTheoryApiState>;
}

const BasisTheoryApiContext = createContext<BasisTheoryContextManager>({
  handleTokenize: async () => ({}),
  instantiateBasisTheory: async () => {},
  bt: {},
  error: {},
  apiKeys: {},
});

export const BasisTheoryApiKeyProvider: FC<fixMe> = ({ children }) => {
  const [apiKeys, setApiKeys] = useState({
    DRIVERS_LICENSE: defaultResponseObject,
    CREDIT_CARD: defaultResponseObject,
    CARD_COMDATA_PROPRIETARY: defaultResponseObject,
    CARD_COMDATA_MASTERCARD: defaultResponseObject,
  });

  async function instantiateBasisTheory(
    tokenType: BasisTheoryTokenType
  ): Promise<void> {
    try {
      fetchFromGraphInternalDevOnly({
        qs: 'q=secureDataTokenizationConfiguration',
        body: jsonStringify({
          query: `query secureDataTokenizationConfiguration(
              $input: SecureDataTokenizationConfigurationInput!
            ) {
              secureDataTokenizationConfiguration(input: $input) {
                iFrameApiKey
                tokenRequestFields {
                  type
                  id
                  mask
                  searchIndexes
                  fingerprintExpression
                  containers
                }
              }
            }
            `,
          variables: {
            input: {
              tokenType: tokenType,
            },
          },
        }),
      }).then(async (response) => {
        const res = await response.json();
        setApiKeys((state) => {
          return {
            ...state,
            [tokenType]: res?.data?.secureDataTokenizationConfiguration,
          };
        });
      });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
    }
  }

  function formatDataPayload(
    tokenType: BasisTheoryTokenType,
    data: BasisTheoryDataPayload
  ): string | Record<string, string> {
    if (tokenType === 'DRIVERS_LICENSE') {
      return {
        [tokenTypePayloadKeyMap[tokenType]]: data,
      };
    }

    return data;
  }

  const handleTokenize = useCallback(
    async ({
      tokenType,
      tokenRequestFields,
      data,
      metadata,
      bt,
      apiKey,
    }: HandleTokenizeKwargs) => {
      if (bt) {
        try {
          const genericToken = await bt.tokens.create(
            {
              type: tokenRequestFields?.type,
              containers: tokenRequestFields?.containers,
              mask: tokenRequestFields?.mask,
              id: tokenRequestFields?.id,
              data: formatDataPayload(tokenType, data),
              metadata: {
                ...metadata,
              },
            },
            { apiKey: apiKey }
          );
          return genericToken;
        } catch (error) {
          return new Error(error as anyOk);
        }
      }
      return null;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const value: BasisTheoryContextManager = useMemo(
    () => ({ handleTokenize, instantiateBasisTheory, apiKeys }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [apiKeys]
  );

  return (
    <BasisTheoryApiContext.Provider value={value}>
      {children}
    </BasisTheoryApiContext.Provider>
  );
};

export const useBasisTheoryCtx = (): BasisTheoryContextManager => {
  const context = useContext<BasisTheoryContextManager>(BasisTheoryApiContext);

  if (!context) {
    throw new Error(
      'useBasisTheoryApiKey must be used within a BasisTheoryApiKeyProvider'
    );
  }
  return context;
};
